import { clsx } from 'clsx'
import { type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokCardType } from '../../model/storyblokTypes.generated'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

const getBorderRadius = (rounded?: string | number): string => {
  return clsx(
    rounded === 'none' && 'rounded-none',
    rounded === 'small' && 'rounded-lg',
    rounded === 'medium' && 'rounded-2xl',
    rounded === 'large' && 'rounded-3xl',
    rounded === 'full' && 'rounded-full',
  )
}

export const StoryblokCard: FC<StoryblokComponentProps<StoryblokCardType>> = ({
  blok: { background, content, padding, rounded },
}) => {
  return (
    <div
      className={clsx(
        'flex flex-row items-center justify-center gap-6',
        getBorderRadius(rounded),
      )}
      style={{ background, padding }}
    >
      <StoryblokComponentsRenderer bloks={content} />
    </div>
  )
}
